<template>
  <div class="home">
    <left-bar></left-bar>
    <div class="container">
      <top-line></top-line>
      <div class="container__wrapper">
        <div class="user__error" v-if="isFind === false">
          Не найдено
        </div>
        <div class="user__wrapper" v-else>
          <div class="user__head">
            <div class="user__name">{{ user.fullName }}</div>
            <div class="user__class">
              <div>{{ user.educationObject }}</div>
              <div>{{ user.schoolClass }}</div>
            </div>
            <div class="user__info">
              <div>{{ user.phone }}</div>
              <div>{{ user.email }}</div>
            </div>
            <div class="user__pay">
              <div>Оплачен до {{ user.payTo }}</div>
              <div>Автоплатеж
                <span v-if="user.isReccurent">включен</span>
                <span v-else>выключен</span>
              </div>
            </div>
          </div>
          <div class="user__devices" v-if="user.devices && user.devices.length">
            <div class="devices">
              <div class="devices__head">
                Устройства:
              </div>
              <div class="devices__wrap">
                <div class="devices__item" @click="activeDevice = device.id" v-for="device in user.devices" :key="device.id" :class="device.id === activeDevice ? 'active' : ''">
                  <span class="devices__circle" :class="device.lastOnline ? 'offline' : 'online'"></span>
                  <div class="devices__name">
                    {{device.name}}
                  </div>
                  <div class="devices__date">
                    {{device.lastOnline}}
                  </div>
                </div>
              </div>
            </div>
            <div class="history">
              <div class="history__item" v-for="history in getHistories" :key="history.id">
                <div class="history__text">{{history.text}}</div>
                <div class="history__date">{{history.createdAt}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftBar from "../components/layouts/LeftBar";
import TopLine from "../components/layouts/TopLine";

export default {
  name: "UserItem",
  components: {TopLine, LeftBar},
  data() {
    return {
      user: {},
      activeDevice: null,
      history: [],
      isFind: null
    }
  },
  computed: {
    getHistories() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.history
    }
  },
  methods: {
    async loadUser() {
      const resp = await this.$axios.get(`statistic/user/${this.$route.params.id}`)
      if (resp.status === 200) {
        this.user = resp.data
        this.isFind = true
      } else {
        this.isFind = false
      }
    },
    async loadDevice() {
      const resp = await this.$axios.get(`statistic/device/${this.activeDevice}`)
      if (resp.status === 200) {
        this.history = resp.data
      }
    }
  },
  watch: {
    activeDevice() {
      this.history = []
      this.loadDevice()
    }
  },
  async mounted() {
    await this.loadUser()
    if (this.user.devices && this.user.devices.length) {
      this.activeDevice = this.user.devices[0].id
    }
  }
}
</script>

<style scoped lang="scss">
.user {
  &__devices {
    padding-top: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__error {
    padding-top: 5vh;
    font-size: 48px;
    color: red;
    text-align: center;
  }

  &__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name, &__pay {
    width: 30%;
  }

  &__name {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: #3B3F48;
  }

  &__class, &__info {
    width: 20%;
  }

  &__info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #3668D8;
  }

  &__class {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3B3F48;
  }

  &__pay {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    color: #3B3F48;
  }
}
.history {
  height: 424px;
  overflow: hidden;
  max-width: 640px;
  width: 70%;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.044799);
  border-radius: 6px;
  position: relative;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2px 15px;
  }
  &__text {
    width: 65%;
    background: #FFFFFF;
    border-radius: 18px;
    padding: 8px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3B3F48;
    text-transform: uppercase;
  }
  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    position: relative;
    top: 5px;
    /* identical to box height */

    text-align: right;

    color: #3B3F48;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    background: rgba(0, 0, 0, 0.03);
    height: 100%;
  }
}
.devices {
  max-width: 280px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.044799);
  border-radius: 6px;
  width: 30%;
  &__wrap {
    height: 424-24+0px;
    overflow: auto;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 9px 8px;
    cursor: pointer;
    &.active {
      background: rgba(0, 0, 0, 0.05)
    }
  }
  &__name {
    padding-left: 24px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #3B3F48;
  }
  &__date {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 11px;
    text-align: right;
    color: #3B3F48;
  }
  &__circle {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 10em;
    display: block;
    &.online {
      background-color: #0AFF00;
    }
    &.offline {
      background-color: #890B0B;
    }
  }
  &__head {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    padding: 5px 7px;

    color: #3B3F48;
  }
}

.container {
  padding-left: 240px;

  &__wrapper {
    padding: 30px 40px;
    max-width: 1080px;

  }
}

.home {
  //padding-bottom: 15vh;
}
</style>