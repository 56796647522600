<template>
  <div class="topline">
    <div class="topline__wrapper">
      <div class="topline__name">
        <span v-if="$route.name === 'Home'">
                  {{ getSchoolName }}
        </span>
      </div>
      <div class="topline__csv">
        <a ref="load" @click="loadCsv">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C6.42955 0 6.77778 0.348223 6.77778 0.777778V5H10.9554C11.7875 5 12.2556 5.95708 11.7447 6.61394L6.78935 12.9851C6.38899 13.4999 5.61101 13.4999 5.21065 12.9851L0.255286 6.61394C-0.255603 5.95708 0.212491 5 1.04464 5H5.22222V0.777778C5.22222 0.348223 5.57045 0 6 0Z"
                fill="white"/>
          </svg>
          <span>CSV</span>
        </a>
        <a ref="loader" style="display:none;"></a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TopLine",
  data() {
    return {
      school: {},
      csvData: [],
      isLoad: false
    }
  },
  methods: {
    async loadCsv() {
      let link = 'statistic/csv'
      if (this.$route.query.class) {
        link = `${link}?class=${this.$route.query.class}`
      } else if (this.$route.query.school) {
        link = `${link}?school=${this.$route.query.school}`
      }
      const resp = await this.$axios.get(link)
      if (resp.data.length) {
        this.generateCsv(resp.data)
      }
    },
    generateCsv(data) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(data[0]).join(";"),
        ...data.map(item => Object.values(item).join(";"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
      csvContent = encodeURI(csvContent)
      this.$refs.loader.setAttribute('href', csvContent)
      this.$refs.loader.setAttribute("download", "mycsv.csv")
      this.$refs.loader.click();
    },
    async loadSchool() {
      if (this.$route.query.school) {
        const resp = await this.$axios.get(`education/${this.$route.query.school}`)
        if (resp.status === 200) {
          this.school = resp.data
        }
      }
    }
  },
  computed: {
    getSchoolName() {
      if (this.$route.query.school) {
        return this.school.name
      } else {
        return 'ВСЕ УЧРЕЖДЕНИЯ'
      }
    },
    getQuerySchool() {
      return this.$route.query.school
    }
  },
  watch: {
    getQuerySchool() {
      this.loadSchool()
    }
  },
  mounted() {
    this.loadSchool()
  }
}
</script>

<style scoped lang="scss">
.topline {
  background: #F8F8F8;
  height: 56px;
  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1020px;
    width: 100%;
  }

  &__name {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    padding: 0 18px;
    color: #3B3F48;
  }

  &__csv {
    a {
      text-decoration: none;
      background: #5D97FF;
      border-radius: 9px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      height: 34px;
      border: none;
      color: #fff;
      cursor: pointer;

      svg {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}
</style>